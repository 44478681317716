import { createCollectionReducer, DELETED } from 'ext/lib/collections';
import {
  TYPE,
  SET_LOCALE,
  REMOVE_DRAFT_LOCALE,
  CLEAR_DRAFT_LOCALES,
} from './actions';

const collectionReducer = createCollectionReducer(TYPE);

export default function reducer(state = {}, action) {
  if (action.type === SET_LOCALE) {
    return {
      ...state,
      active: action.payload.id,
      draftLocales: [...(state?.draftLocales ?? []), action.payload.id],
    };
  }

  if (action.type === REMOVE_DRAFT_LOCALE) {
    return {
      ...state,
      draftLocales: state.draftLocales.filter(id => id !== action.payload.id),
    };
  }

  if (action.type === CLEAR_DRAFT_LOCALES) {
    return {
      ...state,
      draftLocales: [],
    };
  }

  return collectionReducer(state, action);
}

export const getLanguageCode = value => {
  try {
    return value.conditions.and[0].properties.value.split('\n')[0];
  } catch {
    return null;
  }
};

export const selectLocales = state => {
  const entriesWithAddedLangCode = Object.entries(state[TYPE]).map(
    ([key, value]) => {
      return [
        key,
        {
          ...value,
          languageCode: getLanguageCode(value),
        },
      ];
    }
  );

  return Object.fromEntries(
    entriesWithAddedLangCode.filter(
      ([, locale]) => !locale[DELETED] && locale?.name
    )
  );
};
export const selectLocale = (state, id) =>
  state[TYPE][id] ?? state[TYPE]?.draftLocales?.[id];
export const selectActiveLocale = state =>
  selectLocale(state, state[TYPE].active);

export const selectDraftLocales = state => state[TYPE].draftLocales;
