export const objectToCssString = (objectStyles, important = false) => {
  return Object.entries(objectStyles).reduce((prev, [key, value]) => {
    // this regex replaces all Capital letters adding a dash
    // in front of it, and then we make the whole string lowercase
    // e.g flexWrap => flex-Wrap => flex-wrap
    const cssKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();

    return `${prev}${cssKey}: ${value}${important ? ' !important' : ''};`;
  }, '');
};
