import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'locales';

export const {
  reject,
  resolve,
  send,
  create,
  append,
  flush,
  prune,
  remove,
  update,
  patterns,
} = createLifecycleFor(TYPE);

export const SET_LOCALE = '@locales/SET_LOCALE';
export const setLocale = (id, { updateDirection } = {}) => ({
  type: SET_LOCALE,
  payload: { id, updateDirection },
});

export const REMOVE_LOCALE = '@locales/REMOVE_LOCALE';
export const removeLocale = localeId => ({
  type: REMOVE_LOCALE,
  payload: { localeId },
});

export const REMOVE_DRAFT_LOCALE = '@locales/REMOVE_DRAFT_LOCALE';
export const removeDraftLocale = id => ({
  type: REMOVE_DRAFT_LOCALE,
  payload: { id },
});

export const CLEAR_DRAFT_LOCALES = '@locales/CLEAR_DRAFT_LOCALES';
export const clearDraftLocales = () => ({
  type: CLEAR_DRAFT_LOCALES,
});

export const UPDATE_TRANSLATION = '@locales/UPDATE_TRANSLATION';
export const updateTranslation = payload => ({
  type: UPDATE_TRANSLATION,
  payload,
});

export const AUTO_TRANSLATE = '@locales/AUTO_TRANSLATE';
export const autoTranslate = payload => ({
  type: AUTO_TRANSLATE,
  payload,
});
