import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import { INITIALIZE } from 'ext/root/root-actions';
import {
  reject,
  resolve,
  send,
  append,
  prune,
  flush,
  patterns,
} from './actions';

export function* fetchLocales() {
  const logger = yield getContext('logger');
  try {
    const api = yield getContext('api');
    const response = yield call(api.getLocales);

    yield put(resolve(keyBy(response, 'id')));
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
  }
}

export function* createLocale({ payload }) {
  const logger = yield getContext('logger');
  try {
    yield put(send());

    const api = yield getContext('api');
    const locale = yield call(api.createLocale, payload);

    yield put(append({ [locale.id]: locale }));
  } catch (error) {
    logger.error(error);
  }
}

export function* removeLocale({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id } = payload;
    const api = yield getContext('api');

    yield call(api.removeLocale, id);
    yield put(prune(id));
  } catch (error) {
    logger.error(error);
  }
}

export function* updateLocale({ payload }) {
  const logger = yield getContext('logger');
  try {
    const { id, delta } = payload;
    const api = yield getContext('api');

    yield call(api.updateLocale, id, delta);
    yield put(flush(id));
  } catch (error) {
    logger.error(error);
  }
}

export function* autoTranslateXliff({ payload }) {
  const logger = yield getContext('logger');

  try {
    const { xliff } = payload;
    const api = yield getContext('api');

    // start loading
    yield put(send());

    const updatedXLIFF = yield call(api.autoTranslate, xliff);
    return updatedXLIFF;
  } catch (error) {
    yield put(reject(error));
    logger.error(error);
    return error;
  }
}

export default function* LocalesSaga() {
  yield takeEvery([INITIALIZE], fetchLocales);
  yield takeEvery(patterns.create, createLocale);
  yield takeEvery(patterns.remove, removeLocale);
  yield takeEvery(patterns.update, updateLocale);
}
